@import "./var";
@import "./tool";

.page{
  display: flex;
  flex-direction: column;
  header,footer{
    flex-shrink: 0;
  }
  main{
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
  }
}

.page-en{
  word-break: break-word;
  text-align: justify;
  .article-content{
    word-break: break-word;
    text-align: justify;
    *{
      word-break: break-word;
      text-align: justify;
    }
  }
}

.text-yellow{
  color: @yellow !important;
}

.text-gray{
  color: @gray !important;
}

.text-red{
  color: @red !important;
}

.text-blue{
  color: @blue !important;
}

.text-light-black{
  color: @light-black !important;
}

.text-black{
  color: @black !important;
}

.btn{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  background-color: @blue;
  color: #fff;
  padding: 0 20px;

  .btn-color(@bg-color,@border-color: @bg-color,@color: @bg-color){
    background: @bg-color;
    color: #fff;
    border: 1px solid @border-color;
    &.btn-line{
      color: @color;
      background: #fff;
      border: 1px solid @color;
      &:hover{
        background-color: @border-color;
        color: #fff;
      }
    }
  }


  &.btn-yellow{
    background-color: @yellow;
    border: 1px solid @yellow;
    color: @blue;
    &.btn-line{
      border: 1px solid @blue;
    }
  }

  &.btn-square{
    border-radius: 0 !important;
  }

  &.btn-radius{
    border-radius: 25px !important;
  }

  &.btn-block{
    display: flex;
    width: 100%;
  }

  &[disabled]{
    opacity: .6;
    pointer-events: none;
  }
}

input,select, textarea{
  background-color: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #ededed;
  color: @light-black;
}

textarea{
  border-radius: 4px;
  resize: none;
  width: 100%;
  font-size: 12px;
}


.tag{
  border-radius: 0 12px 0 12px;
  background-color: @yellow;
  color: @blue;
  .flex-center;
}

div.msgBoxBackGround {
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.6;
  z-index: 1000;
}
div.msgBox {
  box-sizing: border-box;
  padding: 15px;
  position: fixed;
  z-index: 1050;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.3);
  .msgBoxTitle {
    width: 100%;
    border-bottom: 1px solid #eaeaea;
    color: #333;
    font-size: 18px;
  }
  .msgBoxImage {
    display: none;
  }
  .msgBoxContent {
    color: #333;
    font-size: 14px;
    line-height: 1.6;
    span{
      color: #333;
    }
  }

  div.msgBoxButtons {
    display: flex;
    justify-content: space-around;
  }
}

.image-source-link {
  color: #98C3D1;
}

.summary-list {
  > li {
    .summary-list__title {
      color: @light-black;
    }

    .summary-list__desc {
      color: @red;
    }

    & + li {
    }
  }

  &.summary-list-reverse {
    > li {

      .summary-list__title {
      }

      .summary-list__desc {
      }
    }
  }
}

.data-panel-list{
  display: flex;
  align-items: center;
  >li,.item{
    .number{
      font-size: 30px;
      font-weight: bold;
      white-space: nowrap;
    }
    .desc{
      font-size: 16px;
    }
  }
}

.tab-wrapper{
  .tab-content-list{
    .tab-content-item{
      display: none;
      &.active{

      }
    }
  }
}

.banner{
  position: relative;
  font-size: 0;
  overflow: hidden;
  img{
    max-width: 100%;
    height: auto;
  }
  &:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(4,4,4,0.26);
  }
  .title-wrapper{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    .flex-center;
    .title{
      font-weight: bold;
      font-size: 30px;
      color: #fff;
    }
  }
}



.bg-gray{
  background-color: @bg-gray;
}

.page-bg-gray{
  .bg-gray;
}

.title-deco-left-wrapper{
  padding: 25px 0 20px;
  border-bottom: 1px solid @border;
  .title{
    padding-left: 15px;
    position: relative;
    font-size: 24px;
    color: @blue;
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 4px;
      height: 24px;
      margin-top: -12px;
      background-color: @blue;
    }
  }
}

.sidebar{
  .sidebar-item{
    background-color: #fff;
  }
}

.panel{
  background-color: #fff;
}

.title-desc{
  color: @light-black;
  align-items: baseline;
  &__title{
    flex-shrink: 0;
  }
  &__desc{

  }
}


.grid-item {
  width: 31%;
  margin: 0 1% 20px 1%;
  text-align: justify;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
  border-radius: 3px;
  img {
    margin-bottom: 0;
    width: 100%;
    height: auto;
    display: block;
  }
}


.donation-detail-from{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  margin-left: -2px;
  margin-bottom: 7px;
  .input{
    border-radius: 0;
    padding-left: 5px;
    padding-right: 5px;
    width: 100px;
    background-color: #fff;
    height: 30px;
    display: inline-flex;
    align-items: center;
    border: 1px solid #dcdcdc;
    color: #b4b4b4;
    margin-left: 2px;
    margin-bottom: 5px;
  }
  button[type=submit]{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 2px;
    border-radius: 0;
    height: 30px;
    width: 50px;
    color: @black;
    margin-bottom: 5px;
  }
}

.donate-table{
  width: 100%;
  font-size: 14px;
  text-align: left;
  tr{
    border-bottom: 1px solid @border-light;
  }
  th,td{
    vertical-align: middle;
  }
  thead{
    tr{
      th{
        height: 40px;
      }
    }
  }
}


.daterangepicker td.active, .daterangepicker td.active:hover{
  background-color: @blue !important;
  border-color: @blue !important;
}


//弹出信息框，一般用于ajax信息返回
.msgBoxBackGround {
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.6;
  z-index: 1000;
}
.msgBox {
  width: 500px !important;
  left: 50% !important;
  margin-left: -250px;
  box-sizing: border-box;
  padding: 15px;
  position: fixed;
  z-index: 1050;
  background-color: #fff;
  border-radius: 5px;
  .msgBoxTitle {
    margin-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid @border;
    span{
      display: inline-block;
      font-size: 18px;
      border-bottom: 3px solid @blue;
      margin-bottom: -2px;
      height: 36px;
      line-height: 36px;
    }
  }
  .msgBoxImage {
    display: none;
  }
  .msgBoxContent {
    color: @light-black;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .msgBoxButtons {
    text-align: center;
    input[type='button'] {
      height: 40px;
      line-height: 40px;
      color: #fff;
      background-color: @blue;
      margin: 0 10px;
      outline: none;
      &:focus{
        outline: none;
      }
    }
  }
}
//弹出信息框结束

.img-column-list.point-list .point-tag{
  background-color: @yellow!important;
}
 /* 青旅馆 */
.img-column-list.point-list .point-tag.point-type-second{
   background-color: @blue!important;
 }
/* 社区馆 */
.img-column-list.point-list .point-tag.point-type-third{
  background-color: @orange!important;
}

