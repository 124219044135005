@media screen and (max-width: 1367px) {
	.page-index{
		.swiper-index-banner{
			margin-bottom: 70px;
		}
		.support-list{
			margin-bottom: 10px;
		}
		.title-with-stars-wrapper{
			margin-bottom: 30px;
		}
		.index-news-list{
			margin-bottom: 75px;
			>.item{
				&:first-child,&:nth-child(2){
					padding-top: 0;
				}
				a{
					padding: 20px;
				}
			}
		}
		.donate-info{
			margin-bottom: 110px;
		}
		.brand-project-wrapper{
			margin-bottom: 70px;
		}
		.project-result-wrapper{
			margin-bottom: 75px;
			padding-top: 50px;
			height: 733px;
			&.project-result-wrapper__en{
				height: 763px;
			}
		}
		.home-partner-wrapper{
			margin-bottom: 40px;
			.tab-wrapper{
				.tab-list{
					margin-bottom: 35px;
				}
			}
		}
	}
}

