@import '../../../commonStyle/var';
@import '../../../commonStyle/tool';
@import './var';


.header{
	height: @header_height;
	box-shadow: 0 2px 12px 0 rgba(2, 20, 40, 0.1);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 5;
	background-color: #fff;
	.container{
		height: @header_height;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		.logo{
			color: #fff;
			height: @header_height;
			display: inline-flex;
			align-items: center;
			img{
				height: 48px;
			}
		}
		.header-right{
			position: relative;
			height: @header_height;
			.flex-center;
			&__left{
				display: flex;
				align-items: flex-end;
				justify-content: center;
				flex-direction: column;
			}
			&__top{
				font-size: 14px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin-bottom: 7px;
				.language-box{
					display: flex;
					align-items: center;
					margin-left: 25px;
					a{
						color: @light-black;
						position: relative;
						& + a{
							margin-left: 8px;
							padding-left: 8px;
							&:before{
								content: '';
								position: absolute;
								left: 0;
								top: 4px;
								height: 12px;
								border-left: 1px solid @light-black;
							}
						}
					}
				}
				.login-area{
					position: relative;
					a{
						display: flex;
						align-items: center;
						color: @blue;
						>img{
							width: 20px;
							height: 20px;
							border-radius: 3px;
							display: inline-block;
							margin-right: 2px;
						}
						.caret{
							display: inline-block;
							width: 0;
							height: 0;
							margin-left: 4px;
							vertical-align: middle;
							border-top: 4px solid @blue;
							border-right: 4px solid #fff;
							border-left: 4px solid #fff;
						}
					}
					.login-area__menu{
						position: absolute;
						top: 100%;
						right: 0;
						background-color: #fff;
						border-radius: 4px;
						display: none;
						padding-top: 5px;
						padding-bottom: 5px;
						width: 160px;
						z-index: 2;
						box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
						>li{
							a{
								display: block;
								color: @light-black;
								height: 26px;
								line-height: 26px;
								padding-left: 10px;
								&:hover{
									background-color: @blue;
									color: #fff;
								}
							}
						}
					}
				}
				.search_form{
					margin-left: 25px;
					position: relative;
					.search-content{
						input[type=text]{
							background-color: #fff;
							width: 160px;
							height: 25px;
							border-radius: 25px;
							padding-left: 10px;
							padding-right: 30px;
						}
					}
					.search-btn{
						position: absolute;
						right: 0;
						top: 0;
						width: 30px;
						height: 100%;
						.flex-center;
						cursor: pointer;
						background-color: transparent;
					}
				}
			}
			&__bottom{
				display: flex;
				align-items: center;
				.nav-list{
					display: flex;
					align-items: center;
					>li{
						position: relative;
						&:after{
							content: '';
							height: 20px;
							position: absolute;
							left: 0;
							top: 17px;
							width: 100%;
							background-color: transparent;
						}
						& + li{
							margin-left: 35px;
						}
						>a{
							font-size: 16px;
							color: #1a1a1a;
						}
						&.active{
							>a{
								color: @blue;
							}
						}
						.submenu{
							display: none;
							position: absolute;
							left: 0;
							top: 37px;
							width: 180px;
							background-color: #fff;
							box-shadow: @content_box-shadow;
							>li{
								a{
									height: 50px;
									line-height: 50px;
									padding-left: 30px;
									display: block;
									color: @black;
								}
								&:hover{
									a{
										color: @blue;
									}
								}
								&.active{
									a{
										background-color: @yellow;
									}
								}
							}
						}
					}
				}
			}
		}
		.month-donate{
			margin-left: 20px;
		}
	}
}



.footer-banner{
	background-color: @blue;
	min-width: @container_width;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	overflow: hidden;
	.img{
		.img-wrap(50%, 100%);
		height: auto;
	}
	&__content{
		color: #fff;
		padding-left: 95px;
		align-items: center;
		.item(170px, 170px, 30px);
		width: 580px;
		.qr-code{
			padding: 10px;
			background-color: #fff;
			.img-wrap(170px, 170px);
		}
	}
	&__title{
		line-height: 1.8;
		font-size: 24px;
		margin-bottom: 20px;
	}
	&__desc{
		line-height: 1.8;
		font-size: 16px;
	}
}

.footer{
	background-color: #2f3234;
	.footer-content{
		height: 290px;
		padding-top: 45px;
		.container{
			display: flex;
			align-items: flex-start;
		}
		.footer-item{
			flex-shrink: 0;
			h3{
				font-size: 20px;
				color: #e2e2e2;
				margin-bottom: 40px;
			}
			&.concat{
				color: #e2e2e2!important;
				width: 670px;
				padding-right: 20px;
				p{
					font-size: 14px;
					line-height: 1.2;
					& + p{
						margin-top: 20px;
					}
				}
			}
			&.follow{
				width: 395px;
				.third-party-media{
					margin-bottom: 50px;
					display: flex;
					align-items: center;

					.media-item{
						position: relative;
						display: inline-flex;
						&:hover{
							.third-party-media__qr-wrapper{
								display: block;
							}
						}
						& + .media-item{
							margin-left: 45px;
						}
						i{
							width: 28px;
							height: 28px;
							background-origin: content-box;
							background-size: contain;
							background-position:center;
							background-repeat: no-repeat;
							display: inline-block;
						}
						.third-party-media__qr-wrapper{
							position: absolute;
							z-index: 3;
							left: 100%;
							margin-left: 10px;
							padding: 5px;
							top: -100%;
							margin-top: -14px;
							background-color: #fff;
							box-shadow: @content_box-shadow;
							.img-wrap(120px, 120px);
							display: none;
							overflow: visible;
							&:after{
								content: '';
								position: absolute;
								left: -7px;
								top: 50%;
								width: 0;
								height: 0;
								margin-top: -20px;
								border-top: 20px solid transparent;
								border-right: 20px solid #fff;
								border-bottom: 20px solid transparent;
							}
						}
					}

				}
			}
			&.wechat-qr-code{
				width: 115px;
				.qr-code-wrapper{
					padding: 3px;
					background-color: #fff;
					.img-wrap(115px, 115px);
				}
			}
		}

	}
	.copyright{
		background-color: #25282a;
		height: 48px;
		line-height: 48px;
		overflow: hidden;
		font-size: 14px;
		color: #fff;
		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		a{
			display: inline-block;
			color: #fff;
			margin-right: 20px;
			&:last-child{
				margin-right: 0;
			}
		}
		.tencent-cloud{
			img{
				vertical-align: text-bottom;
			}
		}
	}
}

.page-index{
	.swiper-index-banner{
		min-width: @container_width;
		margin-bottom: 100px;
		font-size: 0;
		width: 100%;
		.swiper-slide{
			display: flex;
			align-items: center;
			justify-content: center;
			a {
				display: block;
				width: 100%;
				img{
					width: 100%;
					height: auto;
				}
			}
		}
		.swiper-prev,.swiper-next{
			cursor: pointer;
			font-size: 0;
			position: absolute;
			top: 50%;
			z-index: 1;
			margin-top: -44px;
			transition: all .3s;
			&.swiper-button-disabled{
				opacity: .3;
			}
		}
		.swiper-prev{
			left: 110px;
		}
		.swiper-next{
			right: 110px;
		}
	}

}

.img-column-list{
	.h-fixed-width-list(30px, 50px, 372px, 248px);
	>.item{
		box-shadow: @content_box-shadow;
		a{
			display: flex;
			flex-direction: column;
		}
		.img-scale;
		&:hover{
			.content{
				background-color: @blue;
				.title{
					color: #fff;
				}
				.desc{
					color: #fff;
				}
				.bottom{
					color: #fff !important;
					.time{
					}
					.icon-desc-wrapper{
						color: #fff;
						.icon{
							.icon-clock-white, .icon-group-white{
								display: inline-block;
							}
							.icon-clock-blue, .icon-group-blue{
								display: none;
							}
						}
					}
				}
			}
		}
		.img{
			position: relative;
		}
		.content{
			transition: all .3s;
			padding: 0 20px;
			height: 164px;
			.title{
				.text-overflow;
				font-size: 20px;
				color: @black;
				line-height: 1.5;
				padding-top: 20px;
				padding-bottom: 18px;
				transition: all .3s;
			}
			.desc{
				height: 40px;
				font-size: 14px;
				color: @light-black;
				line-height: 1.5;
				margin-bottom: 18px;
				transition: all .3s;
				.text-limit2;
			}
			.bottom{
				font-size: 14px;
				color: @gray;
				transition: all .3s;
				display: flex;
				align-items: center;
				.time{

				}
				.icon-desc-wrapper{
					flex-grow: 1;
					flex-shrink: 0;
					margin-left: 0;
					.icon{
						.icon-clock-white, .icon-group-white{
							display: none;
						}
					}
				}
			}
		}
	}
	&.recruit-list{
		>.item{
			.content{
				height: 102px;
				.title{
					padding-top: 18px;
					padding-bottom: 13px;
				}
			}
		}
	}
	&.project-list{
		>.item{
			.content{
				height: 70px;
				.title{
					margin-bottom: 0;
				}
			}
		}
	}
	&.point-list{
		.point-tag{
			position: absolute;
			left: 0;
			top: 0;
			padding: 0 20px;
			height: 36px;
			.flex-center;
			font-size: 14px;
			color: #fff;
			background-color: @blue;
			&.point-tag-yellow{
				background-color: @yellow;
			}
			&.point-tag-orange{
				background-color: @orange;
			}
		}
		>.item{
			.content{
				height: 135px;
				.bottom{
					flex-direction: column;
					align-items: flex-start;
					color: @light-black;
					.bottom-item{
						display: flex;
						align-items: center;
						& + .bottom-item{
							margin-top: 3px;
						}
						.point-address-title{
							flex-shrink: 0;
						}
						.point-address-text{
							.text-overflow;
							width: 300px;
							display: inline-block;
						}
					}
				}
			}
		}
	}
}

.title-with-stars-wrapper{
	text-align: center;
	margin-bottom: 55px;
	.title-with-stars{
		font-size: 28px;
		color: @blue;
		font-weight: bold;
		line-height: 1.5;
		position: relative;
		display: inline-flex;
		.title{
		}
		i{
			position: absolute;
			right: -40px;
			top: -5px;
		}
	}
}

.index-news-list{
	.h-list(30px, 30px , 2);
	margin-bottom: 100px;
	>.item{
		&:hover{
			.content{
				.title{
					color: @blue;
				}
			}
		}
		margin-bottom: 0;
		&:first-child,&:nth-child(2){
			a{
				padding-top: 0;
			}
		}
		a{
			.item(221px, 146px, 25px);
			border-bottom: 1px solid @border-light;
			padding: 30px 0;
			.img-scale;
		}
		.content{
			.title{
				font-size: 18px;
				color: @black;
				margin-bottom: 15px;
				.text-limit2;
			}
			.time{
				margin-bottom: 15px;
				font-size: 14px;
				color: @gray;
			}
			.desc{
				font-size: 14px;
				color: @light-black;
				.text-limit2;
				line-height: 1.5;
			}
		}
	}
}

.page-index{
	.support-list{
		margin-bottom: 56px;
	}
	.news-list{
		margin-bottom: 80px;
	}
	.donate-info{
		.bg(bg-index-donate-info,jpg);
		height: 510px;
		margin-bottom: 150px;
		.donate-info__top{
			margin-bottom: 20px;
			padding-top: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.data-panel-list{
				.item{
					padding-right: 10px;
					&.total-num{
						width: 305px;
					}
					&.year-num{
						width: 290px;
					}
					&.month-num{

					}
				}
			}
			.donate-info__btn{
				display: flex;
				align-items: center;
				.btn{
					width: 135px;
					height: 40px;
					border-bottom: 1px solid #cdcaaf;
					& + .btn{
						margin-left: 40px;
					}
				}
			}
		}
		.donate-info__panel{
			padding: 30px 35px 40px;
			background-color: #fff;
			box-shadow: @content_box-shadow;
			border-radius: 4px;
			.donate-info__panel-meta{
				padding-bottom: 30px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid @border-light;
				.donate-info-swiper{
					font-size: 16px;
					color: @black;
					height: 22px;
					.swiper-slide{
						display: flex;
						align-items: center;
						.donator{
							width: 300px;
							padding-right: 10px;
						}
						.donate-count{
							width: 220px;
							padding-right: 10px;
						}
						.donate-time{
						}
					}
				}
				.search-form{
					width: 280px;
					height: 35px;
					background-color: #ededed;
					border: 1px solid @border;
					position: relative;
					border-radius: 4px;
					input{
						padding-left: 10px;
						padding-right: 40px;
						height: 100%;
						width: 100%;
						background-color: transparent;
					}
					button{
						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						width: 40px;
						.flex-center;
						cursor: pointer;
					}
				}
			}

			.donate-info-panel__main{
				display: flex;
				align-items: flex-start;
				.donate-info-panel__main-item{
					width: 50%;
					margin-top: 25px;
					position: relative;
					&:first-child{
						padding-right: 50px;
						&:after{
							content: '';
							position: absolute;
							top: 10px;
							right: 0;
							bottom: 3px;
							border-right: 1px solid #f4f4f4;
						}
					}
					&:last-child{
						padding-left: 50px;
					}
					.donate-info-panel__main-title{
						color: @blue;
						display: flex;
						align-items: baseline;
						justify-content: space-between;
						padding-bottom: 20px;
						.title{
							font-size: 20px;
						}
						.more{
							font-size: 14px;
						}
					}
					.donate-info-panel__main-item-list{
						font-size: 14px;
						.item{
							& + .item{
								margin-top: 22px;
							}
							a{
								display: flex;
								align-items: center;
								justify-content: space-between;
							}
							.title{
								color: @black;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
							.time{
								color: @gray;
								flex-shrink: 0;
    							margin-left: 10px;
							}
						}
					}
				}
			}

		}
	}

	.brand-project-wrapper{
		margin-bottom: 100px;
		.brand-project-swiper{
			padding: 20px;
			margin: -20px;
			.swiper-slide{
				height: unset;
			}
			.brand-project-item{
				height: 100%;
				.img-scale;
				&:hover{
					.img{
						//.mask{
						//	opacity: 1;
						//	.btn{
						//		transform: translateY(0);
						//	}
						//}
					}
					.content{
						.title{
							color: @blue;
						}
					}
				}
				a{
					height: 100%;
					display: flex;
					flex-direction: column;
				}
				.img{
					position: relative;
					.img-wrap(272px, 181px);

					//.mask{
					//	transition: all .3s;
					//	opacity: 0;
					//	position: absolute;
					//	left: 0;
					//	top: 0;
					//	width: 100%;
					//	height: 100%;
					//	background-color: rgba(31, 31, 31, 0.3);
					//	.flex-center;
					//	.btn{
					//		transition: all .3s;
					//		transform: translateY(10px);
					//	}
					//}
				}
				.content{
					padding: 15px 15px;
					box-shadow: @content_box-shadow;
					flex-grow: 1;
					.title{
						font-size: 20px;
						color: @black;
						.text-overflow;
						margin-bottom: 7px;
					}
					.desc{
						font-size: 14px;
						color: @light-black;
						.text-limit2;
					}
				}
			}

		}
		.brand-project-content-container{
			position: relative;
			.swiper-next,.swiper-prev{
				position: absolute;
				font-size: 0;
				cursor: pointer;
				transition: all .3s;
				&.swiper-button-disabled{
					opacity: .3;
					cursor: default;
				}
			}
			.swiper-next{
				right: -60px;
				top: 50%;
				margin-top: -20px;
			}
			.swiper-prev{
				left: -60px;
				top: 50%;
				margin-top: -20px;
			}
		}
	}

	.project-result-wrapper{
		// height: 763px;
		.bg(bg-index-project-result,jpg);
		padding-top: 80px;
		margin-bottom: 105px;
		&.project-result-wrapper__en{
			// height: 823px;
		}
		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		table{
			text-align: center;
			tr{
				&:first-child{
					td{
						padding-top: 0;
					}
				}
				td{
					padding-top: 55px;
					.number{
						margin-bottom: 15px;
						display: flex;
						align-items: baseline;
						justify-content: center;
						color: @blue;
						font-size: 36px;
						white-space: nowrap;
						.unit-text{
							font-size: 16px;
							margin-left: 10px;
						}
					}
					.desc{
						color: @light-black;
						font-size: 16px;
						word-break: break-word;
					}
					&:first-child{
						padding-left: 95px;
						padding-right: 105px;
					}
					&:last-child{
						padding-right: 95px;
					}
				}
			}
		}
		.add-text {
			margin-bottom: 45px;
			margin-top: 30px;
			padding-left: 95px;
			color: @blue;
			font-size: 16px;
		}
	}

	.home-partner-wrapper{
		padding-bottom: 65px;
		position: relative;
		.tab-wrapper{
			.tab-list{
				margin-bottom: 55px;
				font-size: 22px;
				display: flex;
				align-items: center;
				justify-content: center;
				>li{
					color: @black;
					&.active{
						color: @blue;
					}
					& + li{
						margin-left: 115px;
					}
				}
			}
		}
		.home-partner-swiper{
			margin: 0 -5px;
			.swiper-slide{
				font-size: 0;
				height: 120px;
				padding: 20px 15px;
				.flex-center;
				a{
					height: 80px;
					.flex-center;
					img{
						max-width: 100%;
						max-height: 100%;
					}
				}
			}
		}
		.swiper-pagination{
			bottom: 0;
			width: 100%;
			text-align: center;
			.swiper-pagination-bullet{
				width: 24px;
				height: 8px;
				background-color: #dbdbdb;
				border-radius: 25px;
				opacity: 1;
				margin: 0 10px;
				&.swiper-pagination-bullet-active{
					background-color: @yellow;
				}
			}
		}
	}
}

.page-about{
	.summary{
		margin-bottom: 10px;
	}
	.manager-nav-list{
		margin-bottom: 25px;
	}
	.tab-content-item{
		.article-content{
			margin-bottom: 45px;
		}
		.team-table{
			width: 100%;
			text-align: center;
			td{
				width: calc(100% / 3);
				.img-scale;
				&:hover{
					.content{
						.title{
							font-weight: bold;
						}
					}
				}
				.img{
					.img-wrap(150px, 150px);
					margin-bottom: 25px;
					border-radius: 50%;
					vertical-align: middle;
				}
				.content{
					width: 240px;
					margin-left: auto;
					margin-right: auto;
					padding-bottom: 55px;
					.title{
						margin-bottom: 10px;
						font-size: 18px;
						color: @blue;
						line-height: 1.5;
					}
					.desc{
						font-size: 14px;
						line-height: 1.5;
						color: @light-black;
					}
				}
			}
		}
	}
	.execute-team-list{
		>li{
			.item(150px, 150px, 30px);
			.img-scale;
			.img{
				border-radius: 50%;
			}
			& + li{
				margin-top: 65px;
			}
			&:hover{
				.content{
					.title{
						font-weight: bold;
					}
				}
			}
			.content{
				display: flex;
				flex-direction: column;
				justify-content: center;
				.title{
					font-size: 18px;
					color: @blue;
					margin-bottom: 15px;
				}
				.desc{
					font-size: 14px;
					color: @light-black;
				}
			}
		}
	}
}





@media screen and (max-width: 1500px) {
	.footer-banner{
		.img{
		}
		&__content{
			width: 520px;
			.qr-code{
				padding: 10px;
				background-color: #fff;
				.img-wrap(140px, 140px);
			}
		}
		&__title{
			font-size: 22px;
			margin-bottom: 5px;
		}
		&__desc{
			font-size: 14px;
		}
	}
}


/*引导微信登录*/
.user_login_pannel {
	background-color: #fff;
	padding: 30px;
	width: 595px;
	margin: 30px auto 50px auto;
	box-shadow: 0 2px 12px 0 rgba(5, 36, 70, 0.05);
	border-radius: 4px;
	.category-title{
		margin-bottom: 70px;
	}
	.user_login{
		width: 280px;
		padding-bottom: 100px;
		margin-right: auto;
		margin-left: auto;
		.wechat_qrcode{
			margin-bottom: 20px;
		}
		.desc{
			height: 40px;
			font-size: 0;
			overflow: hidden;
			p{
				font-size: 16px;
				color: #0062cc;
				line-height: 1.2;
				height: 40px;
				float: left;
				font-weight: bold;
			}
			img{
				height: 40px;
				float: left;
				margin-right: 18px;
			}
		}
	}
}


.page-activity-index{
	.sort{
		margin-top: 35px;
		margin-bottom: 30px;
	}
}

.page-active-detail{
	.info-meta{
		.content{
			.content-top{
				.title{
					margin-bottom: 33px;
				}
			}
		}
	}
	.activity-content{
		padding-bottom: 30px;
	}
}

.bd_weixin_popup{
	height: auto !important;
}

.page-product-index{
	.module-desc{
		text-align: center;
		padding: 95px 0 100px;
		.title{
			font-size: 28px;
			color: @blue;
			line-height: 1.5;
			margin-bottom: 40px;
		}
		.desc{
			font-size: 16px;
			line-height: 1.8;
			color: @black;
		}
		.btn{
			margin-top: 55px;
			font-size: 14px;
			padding: 0;
			width: 136px;
			height: 40px;
		}
	}
	.business-wrapper{
		.bg('bg-2.5d',jpg);
		.container{
			height: 700px;
			position: relative;

			.desc{
				position: absolute;
				color: rgb(1, 93, 202);
				font-weight: bold;
				line-height: 2;
				font-size: 18px;
				transform: matrix( 0.84773994657348,0.53041208789354,-0.53041208789354,0.84773994657349,0,0);
				&.left-top-desc{
					top: 260px;
					left: 80px;
					transform: matrix( 0.84773994657348,0.53041208789354,-0.53041208789354,0.84773994657349,0,0);
					&.en{
						top: 263px;
						left: 10px;
					}
				}
				&.right-top-desc{
					top: 223px;
					left: 730px;
					transform: matrix( 0.87114927481767,0.4910182695019,-0.4910182695019,0.87114927481767,0,0);
					&.en{
						top: 217px;
						left: 698px;
					}
				}
				&.left-bottom-desc{
					top: 618px;
					left: 53px;
					transform: matrix( 0.8521486593109,0.52329978256697,-0.52329978256697,0.8521486593109,0,0);
				}
				&.right-bottom-desc{
					top: 578px;
					left: 758px;
					transform: matrix( 0.88474736520224,0.46607091710135,-0.46607091710135,0.88474736520224,0,0);
					&.en{
						top: 587px;
						left: 716px;
					}
				}
				&.center-desc{
					top: 399px;
					left: 407px;
					transform: matrix( 0.86641654306216,0.49932191410774,-0.49932191410774,0.86641654306216,0,0);
					&.en{
						top: 394px;
						left: 368px;
					}
				}
			}

			.guide{
				position: absolute;
				left: 45px;
				top: 44px;
			}
			.pic{
				position: absolute;
				transition: all .3s;
				z-index: 2;
				&:hover{
					&.copy{
						transform: translateY(-4px);
					}
				}
			}
			.center{
				top: 151px;
				left: 420px;
				&.copy{
					top: 149px;
					left: 441px;
				}
			}
			.left-top{
				left: 110px;
				top: 57px;
			}
			.right-bottom{
				top: 366px;
				right: 74px;
			}
			.left-bottom{
				left: 95px;
				top: 380px;
			}
			.right-top{
				top: 23px;
				right: 118px;
			}
		}
	}

	.project{
		text-align: center;
		padding-top: 95px;
		.title{
			font-size: 28px;
			color: @blue;
			margin-bottom: 38px;
			& + .project-list{
				margin-top: 55px;
			}
		}
		.project-tab-list{
			font-size: 18px;
			margin-left: -20px;
			margin-bottom: 30px;
			.flex-center;
			>li{
				width: 210px;
				height: 50px;
				font-size: 18px;
				color: @black;
				border: 1px solid @border;
				border-radius: 4px;
				margin-bottom: 20px;
				a{
					display: block;
					width: 100%;
					height: 100%;
					.flex-center;
				}
				& + li{
					margin-left: 20px;
				}
				&.active{
					background-color: @blue;
					color: #fff;
					border-color: @blue;
				}
			}
		}
	}
}

.page-point-index{
	padding-top: @header_height + @page_top;
	.point-sort{
		margin-bottom: 30px;
	}
	.point-search{
		position: relative;
		width: 465px;
		margin-bottom: 35px;
		input{
			border: 1px solid @border;
			border-right: 0;
			height: 32px;
			width: 100%;
			background-color: #fff;
			padding-left: 10px;
			padding-right: 60px;
			border-radius: 0;
		}
		.btn{
			position: absolute;
			top: 0;
			right: 0;
			width: 50px;
			height: 100%;
			.flex-center;
			border-radius: 0;
		}
	}
}

.page-project-detail{
	.info-meta{
		.content{
			.content-top{
				.btn{
					height: 40px;
					padding-left: 10px;
					padding-right: 10px;
				}
			}
		}
	}
	.tab-content-list{
		padding: 30px 0;
	}
	.article-content{
		padding-top: 0;
	}
	.report-list{
		.h-list(20px, 20px, 3);
		.img{
			.img-wrap(256px, 298px);
		}
	}
}


.page-point-detail{
	.info-meta{
		.title-desc{
			& + .title-desc{
				margin-top: 10px;
			}
		}
		.content{
			.title-desc-row{
				display: flex;
				align-items: center;
				margin-top: 10px;
				.title-desc{
					margin-top: 0;
					width: 300px;
				}
				& + .title-desc{
					margin-top: 10px;
				}
			}

			.content-bottom{
				justify-content: flex-end;
			}
		}
	}
	.belong-to-project{
		margin-left: -5px;
		margin-bottom: -5px;
		display: flex;
		flex-wrap: wrap;
		a{
			margin-left: 5px;
			margin-bottom: 5px;
		}
	}
}

.page-resources{
	.resource-nav-list{
		font-size: 14px;
		margin-bottom: 35px;
		display: flex;
		align-items: center;
		>li{
			position: relative;
			padding: 0 20px;
			& + li{
				border-left: 1px solid #999999;
			}
			a{
				color: @light-black;
			}
			&.active{
				a{
					color: @blue;
				}
			}
		}
	}
}

.page-support-index{
	.article-content{
		margin-bottom: 50px;
	}
}



.go-to-top{
	width: 50px;
	height: 50px;
	background-color: @blue;
	.flex-center;
	position: fixed;
	z-index: 2;
	right: 60px;
	bottom: 250px;
	cursor: pointer;
}

.echart-wrapper{
	width: 680px;
	position: relative;
	.echart{
		z-index: 1;
	}
	.province-data-panel{
		display: none;
		background-color: #fff;
		padding: 25px 30px;
		border-radius: 4px;
		box-shadow: @content_box-shadow;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		transition: top .3s, left .3s;
		.province-name{
			font-size: 20px;
			color: @black;
			white-space: nowrap;
		}
		.province-data-item{
			font-size: 16px;
			white-space: nowrap;
			display: flex;
			align-items: center;
			margin-top: 5px;
			.province-data-title{
				color: @light-black;
			}
			.province-data{
				color: @blue;
			}

		}
	}
	.chart_container {
		height: 700px;
		overflow-y: hidden;
		#echart{
			height: 999px;
			> div {
				top: -378px;
			}
			
		}
	}
}

.page-donate-detail{
	.desc{
		font-size: 14px;
		color: @gray;
		margin-bottom: 20px;
	}
}

.page-site-search{
	.title-underline-box{
		margin-bottom: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.search-type{
			display: flex;
			align-items: center;
			font-size: 16px;
			color: @light-black;
			.search-type-list{
				display: flex;
				align-items: center;
				.item{
					a{
						display: block;
						color: @blue;
						border: 1px solid @blue;
						padding: 0 25px;
						box-sizing: border-box;
						height: 25px;
						line-height: 25px;
					}
					&.active{
						a{
							color: #fff;
							border: none;
							background-color: @blue;
						}
					}
					& + .item{
						margin-left: 10px;
					}
				}
			}
		}
	}
}

.page-donate-detail-lx{

	.financial-header{
		margin-bottom: 23px;
		display: flex;
		align-items: center;
		.financial-header-item{
			color: @black;
			font-size: 18px;
			.financial-header-item__text{
				color: @blue;
				font-size: 24px;
				font-weight: bold;
			}
			& + .financial-header-item{
				margin-left: 165px;
			}
		}
	}
	.donate-desc{
		font-size: 14px;
		color: @gray;
		margin-bottom: 20px;
		line-height: 1.5;
	}
	.main-wrapper{

	}
	.border-container {
		padding: 45px 30px 30px 30px;
		border: 1px dashed @blue;
		margin-bottom: 30px;
		position: relative;
		.donate_title {
			border-radius: 10px;
			width: 150px;
			text-align: center;
			background-color: #fff;
			position: absolute;
			top: 0;
			left: 30px;
			transform: translate(0, -50%);
			border: 1px solid @blue;
			padding: 12px 0;
			font-size: 20px;
			font-weight: bold;
			margin-bottom: 15px;
			color: @blue;
		}
		.donate_limit_date {
			color: #999;
		}
		& + .border-container {
			margin-top: 60px;
		}
	}
}




.page-resource-pics{
	.swiper-container-wrapper{
		height: 600px;
	}
	.swiper-container {
		width: 100%;
		height: 300px;
		margin-left: auto;
		margin-right: auto;
	}

	.swiper-slide {
		background-size: cover;
		background-position: center;
	}

	.gallery-top {
		height: 75%;
		width: 100%;
	}

	.gallery-thumbs {
		height: 25%;
		box-sizing: border-box;
		padding: 10px 0;
	}

	.gallery-thumbs .swiper-slide {
		width: 25%;
		height: 100%;
		opacity: 0.4;
	}

	.gallery-thumbs .swiper-slide-thumb-active {
		opacity: 1;
	}

}


.page-video-detail,.page-resource-pics{
	.title{
		text-align: center;
		font-size: 22px;
		color: @black;
		line-height: 1.5;
		margin-bottom: 30px;
	}
}

.page-resource-detail{
	.page-nav{
		margin-bottom: 20px;
	}
}

.page-about.page-en{
	.banner{
		margin-bottom: 50px;
	}
}

.page-news-index{
	.banner{
		& + .container{
			margin-top: 50px;
		}
	}
}

.page-entry-money-list {
	.main-container-width {
		.text {
			font-size: 14px;
			color: #999;
			margin-bottom: 20px;
			line-height: 1.5;
		}
		.main_container {
			.form_container {
				.input_container {
					display: flex;
					> input {
						width: 250px;
						background-color: #fff;
						border: 1px solid #ccc;
						border-radius: 1px;
						height: 30px;
						line-height: 30px;
						color: #555;
						font-size: 14px;
						padding: 2px 5px;
						margin-right: 2px;
					}
				}
				.button {
					> button {
						font-size: 14px;
						padding: 6px 12px;
						background-color: #ddd;
						border-color: #ccc;
						color: #000;
						border-radius: 1px;
					}
				}
			}
			.list_container {
				margin-top: 10px;
				> table {
					width: 100%;
					background-color: transparent;
					border-collapse: collapse;
    				border-spacing: 0;
					th, td {
						font-size: 13px;
						padding: 5px;
						line-height: 1.5;
						text-align: left;
						&:first-child {
							width: 147px;
						}
						&:nth-child(2) {
							width: 115px;
						}
						&:nth-child(3) {
							width: 560px;
						}
						&:nth-child(4) {
							width: 240px;
						}
					}
					thead {
						th {
							position: sticky;
							top: 0;
							background-color: #fff;
							border-bottom: 2px solid #ddd;
							font-weight: bold;
							vertical-align: bottom;
						}
					}
					tbody {
						tr {
							border-top: 1px solid #ddd;
							&:nth-child(odd) {
								background-color: #f9f9f9;
							}
							td {
								vertical-align: middle;
							}
						}
					}
				}
			}
		}
	}
}