@import '../../../commonStyle/var';
@import '../../../commonStyle/tool';
@import './var';

.container {
  width: @container_width;
  min-width: @container_width;
  margin-left: auto;
  margin-right: auto;
}

.page{
  padding-top: @header_height;
  padding-bottom: 100px;
}

.page-bg-gray{
  padding-top: @header_height + @page_top;
}

.content-container{
  width: @content_container_width;
  .margin-center;
}


.main-container-width{
  width: @main_container_width;
  .margin-center;
}

.btn{
  &.btn-135{
    width: 135px;
    height: 40px;
    padding: 0 10px;
    font-size: 14px;
  }
}

.tag{
  font-size: 16px;
  height: 30px;
  padding: 0 15px;
  &.tag-right-bottom{
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}

.data-panel-list{
  color: @blue;
  .number{
    font-size: 30px;
    margin-bottom: 5px;
  }
  .desc{
    font-size: 16px;
  }
}

.page-nav{
  background-color: #fafafa;
  border-bottom: 1px solid @border;
  margin-bottom: 50px;
  .container{
    .flex-center;
    height: 100px;
    a{
      padding-left: 30px;
      padding-right: 30px;
      border-radius: 25px;
      height: 45px;
      .flex-center;
      color: @black;
      &.active{
        background-color: @blue;
        color: #fff;
      }
      & + a{
        margin-left: 30px;
      }
    }
  }
}

.manager-nav-list{
  font-size: 24px;
  color: @black;
  .flex-center;
  >li{
    & + li{
      margin-left: 135px;
    }
    &.active{
      color: @blue;
      font-weight: bold;
    }
  }
}

.news-list{
  >li{
    &:hover{
      box-shadow: 0 3px 16px 0 rgba(2, 20, 40, 0.18);
      transition: all .3s;
      a{
        .news-content{
          border-color: transparent;
          .content{
            .title{
              color: @blue;
            }
          }
        }
      }
    }
    a{
      .news-content{
        padding: 30px 0;
        .main-container-width;
        border-bottom: 1px solid @border-light;
        .item(220px, 146px, 30px);
        .img-scale;
        .content{
          .title{
            font-size: 20px;
            color: @black;
            .text-overflow;
            margin-bottom: 20px;
          }
          .time{
            font-size: 14px;
            color: @gray;
            margin-bottom: 20px;
          }
          .desc{
            font-size: 14px;
            color: @light-black;
          }
        }
      }
    }
  }
}

.icon-desc-wrapper{
  & + &{
    margin-left: 80px;
  }
}

.article-wrapper{
  .article-header{
    background-color: #fff;
    border-bottom: 1px solid @border-light;
    padding: 25px  20px 15px;
    .title{
      font-size: 24px;
      color: @blue;
      line-height: 1.5;
      margin-bottom: 5px;
    }
    .article-meta{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .article-meta__left{

      }
      .article-share{
        position: relative;
        &:hover{
          .article-share-qrcode-wrapper{
            display: block;
          }
        }
        .btn{
          width: 64px;
          height: 28px;
          padding: 0;
          cursor: default;
        }
        .article-share-qrcode-wrapper{
          position: absolute;
          right: 0;
          top: 42px;
          width: 137px;
          height: 182px;
          box-shadow: 0 3px 16px 0 rgba(2, 20, 40, 0.18);
          padding: 18px 15px 10px;
          text-align: center;
          background-color: #fff;
          z-index: 2;
          display: none;
          .img{
            .img-wrap(100px, 100px);
          }
          .desc{
            color: @light-black;
            font-size: 14px;
          }
        }
      }
    }
  }
  .article-main{
    background-color: #fff;
    padding: 35px 20px 70px;
  }
  .article-comment{
    position: relative;
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      margin-left: -20px;
      width: calc(100% + 40px);
      background-color: @bg-gray;
      height: 20px;
    }
    background-color: #fff;
    padding: 70px 20px;
    .comment-list{
      .comment-item{
        &:first-child{
          padding-top: 0;
        }
        display: flex;
        .item(48px, 48px, 20px);
        align-items: flex-start;
        border-bottom: 1px solid @border-light;
        padding: 20px 0;
        .content{
          .comment-content-header{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 5px;
            .comment-author{
              font-size: 16px;
              color: @black;
              .text-overflow;
              margin-right: 10px;
            }
            .comment-date{
              flex-shrink: 0;
              font-size: 14px;
              color: @gray;
              display: flex;
              align-items: flex-end;
              flex-direction: column;
              .fa-reply{
                margin-right: 3px;
              }
            }
          }
          .comment-content{
            font-size: 14px;
            line-height: 1.5;
            color: @light-black;
          }
          div.comment-content{
            margin-top: 5px;
            color: @black;
            overflow: hidden;
            .btn{
              float: right;
              margin-top: .1rem;
              padding-left: 5px;
              padding-right: 5px;
              height: 24px;
              font-size: 14px;
              i{
                margin-right: 3px;
              }
            }
          }
        }
      }
    }

    .reply-form {
      color: @gray;
      margin-top: 30px;
      .btn{
        width: 95px;
        height: 40px;
        margin-right: 15px;
      }
    }
  }
}

.column-panel{
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  .column-left{
    flex-shrink: 0;
    width: 850px;
    margin-right: 30px;
    padding-top: 16px;
    background-color: #fff;
    .panel;
  }
}




.sidebar{
  flex-grow: 1;


  .sidebar-item{
    padding-bottom: 30px;
    .title-deco-left-wrapper{
      padding-left: @sidebar_space_justify;
      padding-right: @sidebar_space_justify;
    }
    & + .sidebar-item{
      margin-top: 20px;
    }
  }

  .sidebar-container{
    padding-left: @sidebar_space_justify;
    padding-right: @sidebar_space_justify;
  }

  .sidebar-news-list{
    >li{
      display: flex;
      padding: @sidebar_space_justify 0;
      border-bottom: 1px solid @border;
      a{
        width: 100%;
        .item(110px, 70px ,15px);
        .content{
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .title{
            font-size: 16px;
            color: @black;
            line-height: 1.5;
            .text-limit2;
          }
          .bottom{
            color: @gray;
            font-size: 14px;
          }
        }
      }
    }
  }

  .btn-news-list-more{
    margin-top: 20px;
    display: flex;
    height: 35px;
    margin-left: @sidebar_space_justify;
    margin-right: @sidebar_space_justify;
  }

  .sidebar-item-recommend{
    .btn{
      height: 50px;
      font-size: 18px;
      & + .btn{
        margin-top: 20px;
      }
    }
  }
}

h1.category-title {
  color: #026dd7;
  padding-bottom: 15px;
  border-bottom: 1px solid #026dd7;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 40px;
}


.sort{
  font-size: 14px;
  > div {
    display: flex;
    align-items: baseline;
    & + div{
      margin-top: 15px;
    }
  }
  .sort-title {
    color: @black;
    flex-shrink: 0;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: -10px;
    >li{
      display: inline-block;
      margin-right: 20px;
      margin-top: 10px;
      a{
        color: @black;
        font-size: 14px;
      }
      .label{
        .btn;
        font-size: 14px;
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 25px;
        height: 25px;
        line-height: 25px;
      }
    }
  }
}

.panel{
  padding-left: 20px;
  padding-right: 20px;
  & + .panel{
    margin-top: 20px;
  }
}

.title-desc{
  display: flex;
  font-size: 14px;
  &__title{
    width: 75px;
  }
  &__desc{

  }
  & + &, & + .title-desc-row{
    margin-top: 15px;
  }
}



.title-underline-box{
  display: flex;
  align-items: center;
  border-bottom: 1px solid @border;
  .title-underline{
    font-size: 20px;
    padding: 15px 0 10px;
    color: @black;
    &.active{
      color: @blue;
      border-bottom: 4px solid @blue;
    }
    & + .title-underline{
      margin-left: 140px;
    }
  }
  &.tab-list{
    display: flex;
    align-items: flex-start;
    >li{
      display: flex;
      & + li{
        margin-left: 140px;
      }
    }
  }
}

.tab-list{
  .tab-item{
    cursor: pointer;
  }
}





.info-meta{
  .item(460px, 306px, 50px);
  padding-top: 30px;
  padding-bottom: 30px;
  .content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .content-top{
      margin-bottom: 15px;
      .title{
        font-size: 24px;
        line-height: 1.5;
        color: @black;
        margin-bottom: 23px;
      }
      .desc{
        color: @light-black;
        line-height: 1.8;
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
    .content-bottom{
      display: flex;
      align-items: center;
      .btn{
        margin-right: 25px;
        width: 136px;
        height: 40px;
      }
      .share{
        font-size: 14px;
        display: flex;
        align-items: center;
        color: @light-black;
        .bdsharebuttonbox{
          padding-top: 0 !important;
        }
        /* share button */
        .bdshare-button-style0-24 a {
          background-image: url('@{image_url}share-icons.png') !important;
          background-repeat: no-repeat;
          width: 20px!important;
          height: 20px!important;
          padding: 0!important;
          line-height: 1!important;
          margin: 0 5px 0 0 !important;
        }

      }
    }
  }
}

.file-list{
  .h-fixed-width-list(40px, 20px, 240px, 74px);
  font-size: 14px;
  >li{
   .item(55px, 73px, 20px);
    .content{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .file-title{
      color: @light-black;
    }
    .bottom{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .date{
        color: @gray;
      }
      .download{
        width: 45px;
        height: 25px;
        font-size: 14px;
        background-color: @blue;
        color: #fff;
        .flex-center;
      }
    }
  }
}



/*
Component: timeline
--------------------
*/
.timeline {
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0;
    width: 5px;
    background: #ddd;
    left: 30px;
    border: 1px solid #eee;
    margin: 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }
  > li {
    position: relative;
    margin-right: 10px;
    margin-bottom: 15px;
    &:before,&:after{
      display: table;
      content: " ";
    }
    &:after {
      clear: both;
    }
    >.timeline-item {
      background: #fff;
      color: #797979;
      margin-left: 60px;
      margin-right: 15px;
      padding: 5px;
      position: relative;
      border-bottom: 1px solid #f4f4f4;
    }
    &.current{
      > .timeline-item{
        font-weight: bold;
        >.item{
          font-weight: bold;
        }
        > .timeline-header{
          font-weight: bold;
        }
      }
    }
    .timeline-item{
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      > .time {
        color: #999;
        float: right;
        margin: 0 0 0 10px;
        flex-shrink: 0;
        i{
          margin-right: 3px;
        }
      }
      > .timeline-header {
        min-height: 25px;
        margin: 0;
        color: #555;
        padding: 5px;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.5;
        > a {
          font-weight: 600;
        }
      }
      >.timeline-body,> .timeline-footer{
        padding: 10px;
      }
    }

    .time-label{
      > span {
        font-weight: 600;
        padding: 5px;
        display: inline-block;
        background-color: #fff;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
      }
    }

    > .fa, > .glyphicon, > .ion{
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      width: 30px;
      height: 30px;
      font-size: 15px;
      line-height: 30px;
      position: absolute;
      border-radius: 50%;
      text-align: center;
      left: 18px;
      top: 0;
      background-color: #017ffc;
      color: #fff;
    }
  }
}

.tooltipster-base{
  font-size: 14px;
}

.banner{
  min-width: @container_width;
}

.report-list{
  .h-list(30px, 30px, 4);
  >li{
    display: flex;
    flex-direction: column;
    a{
      display: flex;
      flex-direction: column;
    }
    .img-scale;
    .img{
      .img-wrap(274px, 320px);
      margin-bottom: 5px;
    }
    .title{
      font-size: 16px;
      color: @black;
      flex-grow: 1;
      .text-overflow;
      height: 45px;
      line-height: 45px;
      text-align: center;
    }
    .bottom{
      text-align: center;
      .btn{
        font-size: 14px;
        width: 80px;
        height: 24px;
        padding: 0;
      }
    }
  }
}

.btn-date-range{
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  &__cancel{
    background-color: #fff;
    color: @light-black;
    border: 1px solid @border-light;
  }
}

.donate-table{
  tbody{
    tr{
      td{
        padding: 7px 5px 7px 0;
        &.donator{
          padding-left: 5px;
          width: 115px;
        }
        &.date{
          width: 180px;
        }
        &.amount{
          width: 120px;
        }
        &.project{

        }
      }
    }
  }
}



.search-result {
  padding-bottom: 60px;
  .search-item {
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid @border-light;
    &:last-child {
      margin-bottom: 0;
    }
    .anchor {
      color: @blue;
    }
    .top {
      overflow: hidden;
      margin-bottom: 20px;
      .title {
        float: left;
        font-size: 16px;
        line-height: 1;
        margin: 0;
        margin-top: 7px;
        font-weight: 400;
        margin-right: 30px;
      }
      .time {
        float: left;
        transition: none;
        line-height: 1;
        font-size: 14px;
        margin-top: 8px;
        color: @gray;
      }
      .type {
        float: right;
        color: #333;
        border-radius: 0;
        box-shadow: none;
        padding: 0 12px;
        background-color: @yellow;
        height: 30px;
        line-height: 30px;
      }
    }
    .desc{
      font-size: 14px;
      color: @light-black;
      line-height: 1.2;
    }
  }
}


.no-data{
  margin-top: 60px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: @gray;
}
//列表页码
.pagination-box {
  color: @gray;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  .pagination-btn {
    width: 64px;
    height: 24px;
    color: #808080;
    font-size: 14px;
  }
  .prev {
  }
  .next {
  }
  .page-count {
    font-size: 14px;
    color: @light-black;
    > .input {
      width: 34px;
      height: 24px;
      border-radius: 4px;
      border: solid 1px @border;
      padding: 0 4px;
      margin-right: 6px;
      margin-left: 9px;
    }
    > .input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    > .input[type='number'] {
      -moz-appearance: textfield;
    }
    > .confirm {
      margin-left: 12px;
      color: @blue;
      font-size: 14px;
      display: inline-block;
      padding: 0 8px;
      border-radius: 4px;
      border: 1px solid @border;
    }
  }
  .n-list {
    .n {
      color: @light-black;
      font-size: 16px;
      margin-right: 14px;
      border-radius: 50%;
      width: 33px;
      height: 33px;
      display: inline-block;
      text-align: center;
      line-height: 33px;
      &:last-child {
        margin-right: 0;
      }
      &.current, &:hover {
        color: #fff;
        background-color: @blue;
      }
    }
  }
}

.resource-list{
  .h-fixed-width-list(30px, 50px, 272px, 174px);
  >li{
    box-shadow: @content_box-shadow;
    display: flex;
    flex-direction: column;
    .img-scale;
    >a{
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .img-gallery{
      >a{
        display: flex;
        flex-direction: column;
      }
    }
    &:hover{
      .content{
        .title{
          color: @blue;
        }
      }
    }
    .img{
      position: relative;
      .icon{
        z-index: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%) scale(.5);
      }
    }
    .content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 15px 20px;
      .title{
        font-size: 16px;
        color: @black;
        .text-overflow;
        line-height: 1.5;
      }
    }
    .desc{
      margin-top: -10px;
      line-height: 1.5;
      font-size: 14px;
      padding-left: 20px;
      padding-bottom: 15px;
      color: @blue;
      .btn{
        font-size: 12px;
        padding: 0;
        width: 50px;
        height: 24px;
      }
    }
  }
}

.comment-list{
  .empty-list{
    color: @light-black;
  }
}
.comment-form{
  textarea{
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
  }
}




