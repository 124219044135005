//所有项目的公共样式
@import "var";


.flex{
  display: flex;
}

.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}


.text-center{
  text-align: center !important;
}

.text-right{
  text-align: right !important;
}

.over-hidden{
  overflow: hidden !important;
}

.margin-center{
  margin-left: auto;
  margin-right: auto;
}

.text-overflow{
  .over-hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-limit(@lineCount){
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @lineCount;
  word-break: break-all;
  -webkit-box-orient: vertical;
}

.text-limit2{
  .text-limit(2);
}
.text-limit3{
  .text-limit(3);
}
.text-limit4{
  .text-limit(4);
}
.text-limit5{
  .text-limit(5);
}

//图片超出裁剪
.img-wrap(@width,@height: auto) {
  width: @width;
  height: @height;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
  img{
    width: 100%;
    //max-height: 100%;
  }
}

//设置圆形图片
.avatar(@size) {
  border-radius: 50%;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: @size;
  height: @size;
  line-height: @size;
  font-size: 0;
  flex-shrink: 0;
  img {
    border-radius: 50%;
  }
}

.hidden{
  display: none !important;
}

.visibility-hidden{
  visibility: hidden !important;
}

.margin-center{
  margin-left: auto;
  margin-right: auto;
}

strong{
  font-weight: bold;
}

em{
  font-style: italic;
}

i.icon{
  display: inline-block;
}

.triangle{
  width: 0;
  height: 0;
  border-top: 50px solid black;
  border-right: 50px solid red;
  border-bottom: 50px solid green;
  border-left: 50px solid blue;
}

.triangle-left{
  .triangle;
  border-top: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 50px solid transparent;
}
.triangle-right{
  .triangle;
  border-top: 50px solid transparent;
  border-left: 50px solid transparent;
  border-bottom: 50px solid transparent;
}
.triangle-top{
  .triangle;
  border-right: 50px solid transparent;
  border-left: 50px solid transparent;
  border-bottom: 50px solid transparent;
}
.triangle-bottom{
  .triangle;
  border-top: 50px solid transparent;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
}

.img-scale(@img: '.img'){
  .img{
    img{
      transition: all .3s;
    }
  }
  &:hover{
    .img{
      img{
        transform: scale(1.05);
      }
    }
  }
}

.mask-wrapper{
  position: relative;
  &:before{
    content: '';
    opacity: 0;
    transition: all .3s;
    background-color: rgba(51, 51, 51, 0.4);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &:hover{
    &:before{
      opacity: 1;
    }
  }
}

.item(@img-width, @img-height, @space-width){
  display: flex;
  .img{
    .img-wrap(@img-width, @img-height);
  }
  .content{
    flex-grow: 1;
    margin-left: @space-width;
    width: calc(100% - @img-width - @img-height);
  }
}

.h-list(@h-space, @b-space, @count){
  display: flex;
  flex-wrap: wrap;
  margin-left: -@h-space;
  margin-bottom: -@b-space;
  >li, .item{
    margin-left: @h-space;
    margin-bottom: @b-space;
    width: calc((100% - (@count * @h-space)) / @count);
  }
}

.h-fixed-width-list(@h-space, @b-space, @width, @img_height){
  display: flex;
  flex-wrap: wrap;
  margin-left: -@h-space;
  margin-bottom: -@b-space;
  >li, >.item{
    margin-left: @h-space;
    margin-bottom: @b-space;
    width: @width;
    .img{
      .img-wrap(100%, @img_height);
    }
  }
}

.input-unit{
  position: relative;
  .unit{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    .flex-center;
    color: @black;
  }
}

.checkbox-agree{
  display: flex;
  align-items: center;
  font-size: .28rem;
  cursor: pointer;
  label{
    display: inline-flex;
    align-items: center;
  }
  &__desc{
    margin-left: .1rem;
    color: @black;
  }
  &__show-agree{
    margin-left: .1rem;
    color: @red;
    border-bottom: 1px dashed @red;
  }
}

.area-select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .addr-select{
    flex-shrink: 0;
    width: 30% !important;
  }
}

.btn-group{
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.btn2{
    .btn{
      width: calc(50% - .2rem);
    }
  }
  .btn{
    margin-left: .4rem;
    flex-grow: 1;
    flex-shrink: 0;
    &:first-child{
      margin-left: 0;
    }
  }
}

//数字-标题  列表
.summary-list {
  display: flex;
  align-items: center;

  > li {
    flex-grow: 1;
    flex-shrink: 0;
    display: inline-flex;
    flex-direction: column;

    .summary-list__title {
      line-height: 1.2;
      margin-bottom: .15rem;
    }

    .summary-list__desc {
      line-height: 1.2;
    }

    & + li {
      border-left: 1px solid @border;
    }
  }

  &.summary-list_no-border-left{
    >li{
      & + li {
        border-left: 0;
      }
    }
  }

  &.summary-list-reverse {
    > li {
      flex-direction: column-reverse;

      .summary-list__title {
        margin-bottom: 0;
      }

      .summary-list__desc {
        margin-bottom: .15rem;
      }
    }
  }
}

.title-desc-item{
  line-height: 1.5;
  display: flex;
  align-items: flex-start;
  &__title{
    flex-shrink: 0;
    color: @light-black;
  }
  &__desc{
    color: @black;
  }
  &.title-desc-item__column{
    display: block;
  }
}

.en-block{
  word-break: break-word !important;
  text-align: justify;
}

.tab-pane{
  display: none;
  &.active{
    display: block;
  }
}

//posfixed  start
.posfixed-clone{
  position: fixed;
  left: 15px !important;
  right: 15px;
  top: 0;
  transition: all .3s;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 3;
  margin-top: 0 !important;
  transform: translateY(-110%);
  &.active{
    transform: translateY(0);
  }
}

.icon-desc-list{
  display: flex;
  align-items: center;
}

.icon-desc-wrapper{
  display: flex;
  align-items: center;
  font-size: 14px;
  color: @gray;
  .icon{
    width: 30px;
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    i{
    }
  }
}

button[type=submit]{
  &:disabled{
    opacity: .6;
    cursor: default !important;
  }
}

.cursor-pointer{
  cursor: pointer;
}

input,select,textarea{
  &:focus,&:hover{
    outline: none;
  }
}

.mt0{
  margin-top: 0 !important;
}

.mb0{
  margin-bottom: 0!important;
}

.pb0{
  padding-bottom: 0!important;
}


