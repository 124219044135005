@import "./var";

.iconGen(@icon,@width:10px,@height:10px){
  background: url("@{icon_url}@{icon}.png") no-repeat center;
  width: @width;
  height: @height;
  background-origin:content-box;
  background-size: contain;
  display: inline-block;
}

.icon-search-blue{
  .iconGen(icon-search-blue,15px,16px);
}

.icon-month-donate-blue{
  .iconGen(icon-month-donate-blue,44px,42px);
}

.icon-toutiao{
  .iconGen(icon-toutiao,27px,27px);
}

.icon-weibo{
  .iconGen(icon-weibo,28px,28px);
}

.icon-zhihu{
  .iconGen(icon-zhihu,28px,28px);
}

.icon-allow-left{
  .iconGen(icon-allow-left,24px,44px);
}

.icon-allow-right{
  .icon-allow-left;
  transform: rotateZ(180deg);
}

.icon-stars2-blue{
  .iconGen(icon-stars2-blue,29px,38px);
}

.icon-allow-left-swiper{
  .iconGen(icon-allow-left-swiper,40px,40px);
}

.icon-allow-right-swiper{
  .iconGen(icon-allow-left-swiper,40px,40px);
  transform: rotateZ(180deg);
}

.icon-eye-blue{
  .iconGen(icon-eye-blue,20px,11px);
}

.icon-comment-blue{
  .iconGen(icon-comment-blue,14px,14px);
}

.icon-clock-blue{
  .iconGen(icon-clock-blue,16px,16px);
}

.icon-clock-white{
  .iconGen(icon-clock-white,16px,16px);
}

.icon-group-blue{
  .iconGen(icon-group-blue,18px,14px);
}

.icon-group-white{
  .iconGen(icon-group-white,18px,14px);
}

.icon-search-white{
  .iconGen(icon-search-white,15px,16px);
}

.icon-right-top-allow{
  .iconGen(icon-right-top-allow,20px,16px);
}

.icon-left-bottom-allow{
  .iconGen(icon-left-bottom-allow,18px,15px);
}

.icon-left-top-allow{
  .iconGen(icon-left-top-allow,20px,16px);
}


.icon-right-bottom-allow{
  .iconGen(icon-right-bottom-allow,20px,14px);
}

.icon-to-top{
  .iconGen(icon-to-top,24px,25px);
}

.icon-video-play-white{
  .iconGen(video-play-white,113px,113px);
}
