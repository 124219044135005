@import '../../../commonStyle/reset';
@import '../../../commonStyle/common';
@import '../../../commonStyle/var';
@import '../../../commonStyle/tool';
@import './var';
@import './icon.less';
@import './common';
@import './tool';
@import './wj';
@import './media-query';

body {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}
.wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}


